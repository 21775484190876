<template>
  <div id="sitelogin">
    <div class="loginbox" v-loading="loading">
      <div class="titlebox">
        <span>{{shop_name||''}}</span>
        <span>门店登录后台</span>
      </div>

      <div class="inputbox" style="margin-bottom: 40px;">
        <div class="label">登录账号</div>
        <el-input type="text" style="input" v-model="user_name" placeholder="请输入账号"></el-input>
      </div>

      <div class="inputbox">
        <div class="label">登录密码</div>
        <el-input type="password" style="input" v-model="password" placeholder="请输入密码"></el-input>
      </div>

      <el-button class="loginbtn" type="primary" @click='login'>登录</el-button>
    </div>
  </div>
</template>

<script>
import { eltips, getUrlKey } from '@/util/util.js';
import { resetRouter } from '@/router/index.js'
import store from '@/store/index.js'
export default {
  name: 'sitelogin',
  data() {
    return {
      shop_name: '',
      user_name: '',//用户名
      password: '',//密码
      guard_name: '',//应用名称
      shop_id: '',
      loading: false,
    };
  },
  created() {
    if (getUrlKey('name')) {
      this.shop_name = getUrlKey('name')
    }

    if (getUrlKey('shop_id')) {
      this.shop_id = getUrlKey('shop_id')
    }

    if (getUrlKey('guard_name')) {
      this.guard_name = getUrlKey('guard_name')
    }
  },
  methods: {
    //登录
    login() {
      if (!this.user_name) {
        eltips('请输入用户名', 'error')
        return
      }
      if (!this.password) {
        eltips('请输入密码', 'error')
        return
      }

      let data = {
        shop_id: this.shop_id,
        user_name: this.user_name,
        password: this.password
      }

      sessionStorage.setItem('guardName', this.guard_name)

      this.loading = true
      this.axios.post('/api/retail/admin/login', data).then(res => {
        if (res.code == 200) {
          sessionStorage.setItem('token', res.data)
          sessionStorage.removeItem('role_id')
          sessionStorage.removeItem('pluginroute')
          sessionStorage.removeItem('pluginid')
          this.getuserinfo()
        } else {
          this.loading = false
          eltips(res.msg, 'error')
        }
      })
    },
    //获取个人信息
    getuserinfo() {
      this.loading = true
      this.axios.get('/api/me', {
        params: {
          guard_name: this.guard_name
        }
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          sessionStorage.setItem('userinfo', JSON.stringify(res.data))
          this.getmeuns()
        } else {
          this.loading = false
          eltips(res.msg, 'error')
        }
      })
    },
    //获取权限菜单
    getmeuns() {
      this.loading = false
      this.axios.get('/api/get_menu').then(res => {
        this.loading = false
        resetRouter()
        this.$store.commit('routestatus', 0)
        sessionStorage.removeItem('routestext')
        sessionStorage.removeItem('routes')
        sessionStorage.setItem('routes', JSON.stringify(res.data))
        this.$router.replace({ path: "/index" })
        this.loading = false
        let path
        if (res.data[0].path) {
          path = res.data[0].path
        } else {
          let child = res.data[0]._child
          for (let i = 0; i < child.length; i++) {
            if (child[i].path) {
              path = child[i].path
              break
            }
          }
        }
        this.$router.replace({ path: path })
      })
    },
  }
};
</script>


<style>
#sitelogin .el-input__inner {
  border: none !important;
}

#sitelogin .el-button {
  padding: 18px 20px !important;
  font-size: 16px;
  font-weight: bold;
}
</style>
<style scoped lang="less">
#sitelogin {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: url(../../assets/images/login_bg.png);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);
  .loginbox {
    width: 380px;
    background: #ffffff;
    border-radius: 20px;
    padding: 30px;
  }
  .titlebox {
    font-size: 20px;
    color: #1e92ff;
    margin-bottom: 40px;
    font-weight: bold;
  }
  .inputbox {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dedede;
    padding-bottom: 6px;
    .label {
      width: 80px;
    }
    .input {
    }
  }
  .loginbtn {
    margin-top: 160px;
    width: 100%;
  }
}
</style>
